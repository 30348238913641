  <div id="container">
    <div id="grid" class="grid-1">
      <div class="imgh d-flex align-items-center justify-content-center"
           *ngFor="let img of imagenes; index as i">
        <img [src]="img">
      </div>
    </div>

  </div>

  <div class="btons" *ngIf="imagenes.length > 1">
    <button mat-raised-button [color]="'primary'" (click)="moverToIzquierda()">
      <mat-icon>arrow_back_ios</mat-icon>
    </button>
    <button mat-raised-button [color]="'primary'" (click)="moverToDerecha()">
      <mat-icon>arrow_forward_ios</mat-icon>
    </button>
  </div>

