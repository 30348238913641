import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

export class VersionModel {
  local: string;
  development: string;
  testing: string;
  prod: string;
  inherit: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  private jsondata!: VersionModel;
  private env: string = environment.env;
  public version: string = 'unknown';

  constructor(private httpClient: HttpClient) {}

  checkVersion(): void {
    // @ts-ignore
    this.jsondata = require(`../../assets/version.json`);
    const cacheVersion = this.jsondata[this.env];
    console.warn(`cache version: ${cacheVersion}`);

    const headers = new HttpHeaders().set('Cache-Control', 'no-cache').set('Pragma', 'no-cache');
    this.httpClient
      .get<VersionModel>('/assets/version.json', {headers})
      .subscribe(version => {
        this.version = version[this.env];
        console.warn(`new version: ${this.version}`);
        if (this.version !== cacheVersion) {
          window.location.reload();
        }
      });
  }
}
