import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Existencias} from '../../models/respuestas.model';

@Component({
  selector: 'app-modal-existencias',
  templateUrl: './modal-existencias.component.html',
  styleUrls: ['./modal-existencias.component.scss']
})
export class ModalExistenciasComponent implements OnInit {
  centros = [
    'santaRosa',
    'tehuantepec',
    'simbolos',
    'ferrocarril',
    'atzompa',
    'reforma',
    'construcasa',
    'hidalgo',
    'cedis'
  ];
  alm = [
    '21',
    '9',
    '7',
    '6',
    '5',
    '4',
    '3',
    '2',
    '1'
  ];

  totalExistencias: number = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {existencias: Existencias, nombreProducto: string}) { }

  ngOnInit() {
    this.centros.forEach(item => {
      if (this.data.existencias[item] > 0) {
        this.totalExistencias += Number((this.data.existencias[item]).toFixed(2));
      }
    });
  }

}
