import {FiltrosModel} from './filtros.model';

export class ConfiguracionModel {
  viewTarjeta: boolean;
  carritoTable: boolean;
  sucursalId: number;
  sucursalAbv: string;
  sucursalNom: string;
  temporalId: string;
  filtros: FiltrosModel[];

  constructor() {
    this.viewTarjeta = true;
    this.carritoTable = false;
    this.sucursalId = 2;
    this.sucursalAbv = 'B0';
    this.sucursalNom = 'Zona Oaxaca';
    this.filtros = [];
    this.temporalId = 'MOSTRADOR' + new Date().getTime().toString();
  }
}

export class ConfigData {
  id: string;
  quienesSomos: ItemUrl;
  terminos: ItemUrl;
  historia: ItemUrl;
  politicaDefault: PoliticaDefault;
  politicasDevolucion: ItemUrl;
  politicasEnvio: ItemUrl;
  politicasPago: ItemUrl;
  avisoPrivacidad: ItemUrl;
  faqs: ItemUrl;
  facebook: ItemUrl;
  twitter: ItemUrl;
  instagram: ItemUrl;
  sucursales: ItemUrl;
  facturacion: ItemUrl;
  clubTuberos: ItemUrl;
  urlContactos: ItemUrl;
  urlComoTeAtendimos: ItemUrl;
  urlAyuda: ItemUrl;
  whatsAppCel: string;
  callCenter: string;
  correoContacto: string;
  bannerInferior: BannerInferior;
  bannersSuperiores: ImgBanner[];
  apiCorreos: ApiCorreos;
  zona: Zona;
  imgLogo: string;
  codigoProductoEnvio: string;
}

export class ApiCorreos {
  token: string;
  url: string;
  remitente: string;
}

export class BannerInferior {
  img: ImgBanner;
  titulo: string;
  descripcion: string;
  backgroundColor: string;
}

export class ImgBanner {
  img: string;
  link: string;
}

export class ItemUrl {
  url: string;
  visible: boolean;
}

export class Zona {
  nombre: string;
  area: Punto[];
}

export class Punto {
  latitud: number;
  longitud: number;
}

export class PoliticaDefault {
  clienteID: string;
  agenteID: string;
  centroID: number;
  tipoVentaID: string;
}
