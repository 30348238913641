import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-carrusel-vertical',
  templateUrl: './carrusel-vertical.component.html',
  styleUrls: ['./carrusel-vertical.component.scss'],
  animations: [
    trigger('move', [
      state('in', style({transform: 'translateY(0)'})),
      transition('void => left', [
        style({transform: 'translateY(100%)'}),
        animate(200)
      ]),
      transition('left => void', [
        animate(200, style({transform: 'translateY(0)'}))
      ]),
      transition('void => right', [
        style({transform: 'translateY(-100%)'}),
        animate(200)
      ]),
      transition('right => void', [
        animate(200, style({transform: 'translateY(0)'}))
      ])
    ])
  ]
})
export class CarruselVerticalComponent implements OnInit {
  @Input() imagenes: string[] = [];
  sliderButtonsDisable = false;
  state = 'void';

  @Output() seleccionado: EventEmitter<string> = new EventEmitter<string>();
  constructor() {
  }

  ngOnInit() {
  }

  verImg(img: string) {
    this.seleccionado.emit(img);
  }

  /**
   *
   * @param arreglo
   * @param reverse
   */
  rotarImagen(arreglo, reverse) {
    if (reverse) {
      arreglo.unshift(arreglo.pop());
    } else {
      arreglo.push(arreglo.shift());
    }
    return arreglo;
  }

  /**
   * @autor: I. S. C. Edgar Alfred Rodriguez Robles
   * Realiza la instrucción para sacar el primer elemento del arreglo y ponerlo al final
   */
  moverArriba() {
    if (this.sliderButtonsDisable) {
      return;
    }
    this.state = 'right';
    this.rotarImagen(this.imagenes, true);
  }

  /**
   * @autor: I. S. C. Edgar Alfred Rodriguez Robles
   * Realiza la instrucción para sacar el ultimo elemento del arreglo y ponerlo al inicio
   */
  moverAbajo() {
    if (this.sliderButtonsDisable) {
      return;
    }
    this.state = 'left';
    this.rotarImagen(this.imagenes, false);
  }

  /**
   * @autor: I. S. C. Edgar Alfred Rodriguez Robles
   * @param evento:
   */
  onTerminar(evento) {
    this.state = 'void';
    this.sliderButtonsDisable = false;
  }

  /**
   * @autor: I. S. C. Edgar Alfred Rodriguez Robles
   * @param evento:
   */
  onIniciar(evento) {
    this.sliderButtonsDisable = true;
  }

}
