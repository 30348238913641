import {Injectable} from '@angular/core';
import {PlatformService} from './platform.service';

declare var jQuery: any;

@Injectable({
  providedIn: 'root'
})
export class DisplayService {
  isMobile: boolean;

  constructor(private platform: PlatformService) {
    // window.addEventListener('resize', this.onResize);
  }

  checkForMobile(): boolean {
    this.isMobile = false;
    if (this.platform.isBrowser()) {
      this.isMobile = jQuery.browser.mobile as boolean;
    }
    return this.isMobile;
  }
}
