import {NgModule} from '@angular/core';
import {MaterialComponentsModule} from './material-components/material-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoaderComponent} from './loader/loader.component';
import {LottieAnimationViewModule} from 'ng-lottie';
import {NgBootstrapModule} from './ng-bootstrap/ng-bootstrap.module';
import {NotFoundComponent} from './not-found/not-found.component';
import {FooterComponent} from './footer/footer.component';
import {CommonModule} from '@angular/common';
import {CarruselVerticalComponent} from './carrusel-vertical/carrusel-vertical.component';
import {CarruselHorizontalComponent} from './carrusel-horizontal/carrusel-horizontal.component';
import {RouterModule} from '@angular/router';
import {PipesModule} from '../pipes/pipes.module';
import {TarjetaHubComponent} from './tarjeta-hub/tarjeta-hub.component';
import { BannerAnalitycsComponent } from './banner-analitycs/banner-analitycs.component';


@NgModule({
  declarations: [
    LoaderComponent,
    NotFoundComponent,
    FooterComponent,
    CarruselVerticalComponent,
    CarruselHorizontalComponent,
    TarjetaHubComponent,
    BannerAnalitycsComponent
  ],
  imports: [
    CommonModule,
    LottieAnimationViewModule,
    MaterialComponentsModule,
    RouterModule,
    PipesModule
  ],
  exports: [
    MaterialComponentsModule,
    ReactiveFormsModule,
    FormsModule,
    LoaderComponent,
    NgBootstrapModule,
    NotFoundComponent,
    FooterComponent,
    CarruselVerticalComponent,
    CarruselHorizontalComponent,
    TarjetaHubComponent,
    BannerAnalitycsComponent
  ]
})
export class SharedModule {
}
