<div class="container">
  <h2 class="text-center">404 Not Found</h2>

  <button mat-raised-button [color]="'accent'" [routerLink]="['/']">
    <span>Volver al Inicio</span>
  </button>

  <mat-card>
    <h4 class="card-title">
      No fue posible encontrar el enlace solicitado.
    </h4>

    <mat-card-content>
      Por favor asegurese de estar usando un enlace correcto.
      Le sugerimos regresar a la
      <a [routerLink]="['/']" role="tab" data-toggle="tab">
        página principal </a> e intentelo de nuevo.
      <p class="category">
        Si usted cree que recibió este mensaje debido a un error por favor envienos un correo con la
        descripción del mismo a
        <a style="color: hotpink; background-color: transparent; text-decoration: none;" class="category">
          kore.soporte@gmail.com </a>.
      </p>
    </mat-card-content>
  </mat-card>
</div>
