import {Injectable} from '@angular/core';
import {CredencialModel} from '../login.model';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {PerfilUsuario, RespuestaModel} from '../../models/respuestas.model';
import {JwtHelperService} from '@auth0/angular-jwt';
import {PlatformService} from '../platform.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private key = 'EcommerceToken';
  private helper = new JwtHelperService();
  autenticado: boolean;

  constructor(private router: Router,
              private http: HttpClient,
              private platform: PlatformService) {
  }

  login(credenciales: CredencialModel) {
    const url = `${environment.urlLogin}/logink`;
    return this.http.post(url, credenciales).pipe(map((respuesta: RespuestaModel) => {
      if (this.platform.isBrowser()) {
        localStorage.setItem(this.key, respuesta.Data);
      }
      this.autenticado = true;
      return true;
    }));
  }

  logout() {
    this.autenticado = false;
    if (this.platform.isBrowser()) {
      localStorage.removeItem(this.key);
      this.router.navigate(['/']);
    }
  }

  getToken(): string {
    if (this.platform.isBrowser()) {
      return localStorage.getItem(this.key);
    }

    if (this.platform.isServer()) {
      return null;
    }
  }

  isAuth(): boolean {
    this.autenticado = !this.helper.isTokenExpired(this.getToken());
    return this.autenticado;
  }

  getProfile(): PerfilUsuario {
    let token = '';
    if (this.platform.isBrowser()) {
      token = localStorage.getItem(this.key);
    }

    if (token !== '') {
      return this.helper.decodeToken(token);
    }
    return null;
  }


  esAdministrador(): boolean {
    if (!this.isAuth()) {
      return false;
    }

    let token = '';
    if (this.platform.isBrowser()) {
      token = localStorage.getItem(this.key);
    }

    if (token) {
      const perfil = this.helper.decodeToken(token) as PerfilUsuario;
      return this.isValidProfile(perfil.profileskore);
    }
    return false;
  }

  isValidProfile(profileskore: string[]): boolean {
    const validProfiles = ['Administrador', 'MERCADOTECNIA'];
    return validProfiles.some(profile => profileskore.includes(profile));
  }
}
