import { Pipe, PipeTransform } from '@angular/core';
import {FormaPagoModel} from '../models/cuenta.model';

@Pipe({
  name: 'pagoname'
})
export class PagonamePipe implements PipeTransform {

  transform(array: FormaPagoModel[], pagoId: string, NUMPAG: string ): any {

    const forma = array.find(item => item.ID === pagoId);
    if (!forma) {
      return NUMPAG;
    }

    return forma.CatalogoSAT.Descripcion;
  }

}
