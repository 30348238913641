<ng-container *ngIf="showBanner">
  <div class="banner">
    <p class="message">
      Este sitio web utiliza cookies para mejorar tu experiencia. Al continuar
      navegando, aceptas el uso de cookies.
    </p>
    <button
      mat-button
      mat-raised-button
      type="button"
      color="primary"
      (click)="btnAccept()"
    >
      Aceptar
    </button>
    <div class="close-icon">
      <button mat-icon-button (click)="closeBanner()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</ng-container>
