import { Component } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  // tslint:disable-next-line:ban-types
  public lottieConfig: Object;
  private anim: any;
  public width = 150;
  public height = 300;

  constructor() {
    this.lottieConfig = {
      path: 'assets/lottie/loader.json',
      renderer: 'canvas',
      autoplay: true,
      loop: true
    };
  }

  handleAnimation(anim: any) {
    this.anim = anim;
  }

}
