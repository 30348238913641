import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(estatus: number, getClass = false): string {
    const text = this.getTitle(estatus);
    return getClass ? text.replace(' ', '').trim().toLocaleLowerCase() : text;
  }

  getTitle(estatus: number): string {
    switch (estatus) {
      case 0:
        return 'De Linea';
      case 1:
        return 'De Linea';
      case 2:
        return 'Sobrepedido';
      case 3:
        return 'Descontinuado';
      default:
        return 'Sin Definir';
    }
  }

}
