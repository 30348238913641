<div class="tarjeta mat-elevation-z2">
  <div class="row content p-2">
    <div class="col-xs-4 icono">
      <p><i class="material-icons">{{contenido.iconoPrincipal}}</i></p>
    </div>
    <div class="col-xs-8 descripcion pl-2">
      <div>
        {{contenido.titulo}}  <i class="material-icons"
                                 *ngIf="contenido.iconoTitulo"
                                  (click)="editar.emit()">{{contenido.iconoTitulo}}</i>
      </div>
      <p class="descripcion">{{contenido.descripcion}}</p>
    </div>
  </div>

</div>
