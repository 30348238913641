import {HttpErrorResponse} from '@angular/common/http';
import {ErrorModel} from '../models/errores.model';
import {Centros, Existencias} from '../models/respuestas.model';
import {ModalExistenciasComponent} from '../components/modal-existencias/modal-existencias.component';
import { MatDialog } from '@angular/material/dialog';

export function parseError(e: HttpErrorResponse): ErrorModel {
  if (e.status !== 0) {
    const body = e.error ? e.error : {};
    return {
      title: body.Mensaje ? body.Mensaje : e.status === 403 ? 'No tienes permiso de accesar. Valida con tu administrador' : '',
      description: body.Data ? (typeof body.Data === 'string' ? body.Data : body.Data[0]) : ''
    };
  } else {
    return {title: 'Error de conexión', description: 'No se recibió mensaje de respuesta del servidor'};
  }
}
export function aModalExistencias(dialog: MatDialog, exist: Centros, productoName: string, productoCode: string) {
  return dialog.open(ModalExistenciasComponent, {
    maxWidth: '120vw',
    maxHeight: '120vh',
    data: {
      existencias: exist,
      nombreProducto: '(' + productoName + ') ' + productoCode
    }
  });
}
export function abrirModalExistencias(dialog: MatDialog, exist: Existencias, productoName: string, productoCode: string) {
  return dialog.open(ModalExistenciasComponent, {
    maxWidth: '90vw',
    maxHeight: '90vh',
    data: {
      existencias: exist,
      nombreProducto: '(' + productoName + ') ' + productoCode
    }
  });
}

export function descargarDocumento(b: Blob, nombre: string = new Date().toISOString(), extension = '.pdf') {
  const fileURL = URL.createObjectURL(b);
  const link = document.createElement('a');
  link.download = nombre + extension;
  link.href = fileURL;
  document.body.appendChild(link);
  link.click();
  setTimeout(function() {
    document.body.removeChild(link);
  }, 10000);
  return;
}
