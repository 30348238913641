<div id="loader" class="hidden">
  <div id="contenedor">
    <lottie-animation-view id="contenido"
                           [options]="lottieConfig"
                           [width]="width"
                           [height]="height"
                           (animCreated)="handleAnimation($event)">
    </lottie-animation-view>
  </div>
</div>
