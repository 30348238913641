import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'validaNums'
})
export class ValidaNumsPipe implements PipeTransform {

  transform(valor: number, opcion: string): any {
    switch (opcion) {
      case 'menorACero':
        return valor < 0 ? 0 : valor;
    }

  }
}
