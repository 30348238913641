import {environment} from '../../environments/environment';

export const _CONFIG_KEY_ECOMMERCE_ = 'configEcommerce';
export const _DEFAULT_IMAGE_ = `${environment.urlEcommerce}/img/default`;
export const _PUBLICO_GENERAL_ = '2copaosjsrao5'; // Cliente por default
export const _MIN_PARA_ENVIO_GRATIS_ = 0;
export const _REG_EXP_RFC_ = `([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([A-Z]|[0-9]){2}([A]|[0-9]){1})`;
// PATRON DE PASSWORD
// (?=.*\d)      #   must contains one digit from 0-9
// (?=.*[a-z])   #   must contains one lowercase characters
// (?=.*[A-Z])   #   must contains one uppercase characters
// (?=.*[@#$%])   #   must contains one special symbols in the list "@#$%"
//     . #   match anything with previous condition checking
// {6,20}        #   length at least 6 characters and maximum of 20
export const _PASSWORD_PATTERN_ = '((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[#?!@$%^&*-]).{6,20})';

export const _TEST_IMAGENES_MARCAS_: string[] = [
    'assets/testimages/img1.png',
    'assets/testimages/img2.png',
    'assets/testimages/img3.png',
    'assets/testimages/img4.png',
    'assets/testimages/img5.png',
    'assets/imagenes/mtro-beto.png'
];

export const _TEST_ICON_MARCAS_: string[] = [
    'tubos-1',
    'tubos-2',
    'tubos-3',
    'tubos-4',
    'tubos-5',
    'tubos-6',
    'tubos-7',
    'tubos-8',
    'tubos-9',
    'tubos-10',
    'tubos-11',
    'tubos-12'
];
export const _GRUPOSCENTROS_: string[] = [
  // 'J0',
  // 'K0',
  'B0',
  'H0',
];
export const _ZONAS_: any[] = [
  {Nombre: 'Zona Oaxaca', Abreviatura: 'B0'},
  {Nombre: 'Zona Tehuantepec', Abreviatura: 'H0'},
];
export const _GRUPOALMACEN_: any[] = [
  {Padre: 'B0', Abreviatura: 'A0'},
  {Padre: 'B0', Abreviatura: 'B0'},
  {Padre: 'B0', Abreviatura: 'C0'},
  {Padre: 'B0', Abreviatura: 'D0'},
  {Padre: 'B0', Abreviatura: 'E0'},
  {Padre: 'B0', Abreviatura: 'F0'},
  {Padre: 'B0', Abreviatura: 'G0'},
 // {Padre: 'B0', Abreviatura: 'J0'},
  {Padre: 'B0', Abreviatura: 'M0'},
  {Padre: 'H0', Abreviatura: 'H0'},
  // {Padre: 'K0', Abreviatura: 'K0'},
  // {Padre: 'B0', Abreviatura: 'B0'},
];
