import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtro'
})
export class FiltroPipe implements PipeTransform {

  transform(array: any[], operador: string, key: string, valor: any): any[] {
    if (!array) {
      return [];
    }
    switch (operador) {
      case '!==':
        return array.filter(item => item[key] !== valor);
      case '===':
        return array.filter(item => item[key] === valor);
      default:
        return array;
    }
  }

}
