import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordenar'
})
export class OrdenarPipe implements PipeTransform {

  transform(array: any[], key: string): any[] {
    if (!array) {
      return [];
    }
    array = array.sort((a, b) => a[key] < b[key] ? -1 : 1);
    return array;
  }

}
