import { Pipe, PipeTransform } from '@angular/core';
import {environment} from '../../environments/environment';

@Pipe({
  name: 'config'
})
export class ConfigPipe implements PipeTransform {

  transform(url: string): string {
    if (!url) {
      return '';
    }
    return `${environment.urlEcommerce}/${url}`;
  }

}
