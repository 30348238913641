export const _LIST_DEPARTAMENTOS_ = {
  PLOMERIA: 'Plomeria',
  ELECTRICIDAD: 'Electricidad',
  CONSTRUCCIONYACABADOS: 'Construccion',
  BANOSYCOCINAS: 'Banos',
  ILUMINACION: 'Iluminacion',
  ACABADOS: 'Acabados',
  FERRETERIA: 'Ferreteria',
  HERRAMIENTAS: 'Herramientas',
  JARDINERIAYEXTERIORES: 'Jardineria',
  SEGURIDADYELECTRONICA: 'Electronica',
  HOGARYOFICINAS: 'Otros',
  INFRAESTRUCTURAYURBANIZACION: 'Servicios'
};
