import {NgModule} from '@angular/core';
import {OrdenarPipe} from './ordenar.pipe';
import {PrecioPipe} from './precio.pipe';
import {NoimagenPipe} from './noimagen.pipe';
import {StatusPipe} from './status.pipe';
import {CalcularPipe} from './calcular.pipe';
import {TransformtxtPipe} from './transformtxt.pipe';
import {CurrencyPipe, DatePipe, TitleCasePipe} from '@angular/common';
import { DefaultItemPipe } from './default-item.pipe';
import {PagonamePipe} from './pagoname.pipe';
import { ConfigPipe } from './config.pipe';
import { ValidaNumsPipe } from './valida-nums.pipe';
import { FiltroPipe } from './filtro.pipe';
import { HrefsPipe } from './hrefs.pipe';

@NgModule({
  declarations: [
    OrdenarPipe,
    PrecioPipe,
    NoimagenPipe,
    StatusPipe,
    CalcularPipe,
    TransformtxtPipe,
    DefaultItemPipe,
    PagonamePipe,
    ConfigPipe,
    ValidaNumsPipe,
    FiltroPipe,
    HrefsPipe
  ],
    exports: [
        OrdenarPipe,
        PrecioPipe,
        NoimagenPipe,
        StatusPipe,
        CalcularPipe,
        TransformtxtPipe,
        DefaultItemPipe,
        PagonamePipe,
        ConfigPipe,
        ValidaNumsPipe,
        FiltroPipe,
        HrefsPipe
    ],
  providers: [
    OrdenarPipe,
    PrecioPipe,
    NoimagenPipe,
    StatusPipe,
    TransformtxtPipe,
    TitleCasePipe,
    CurrencyPipe,
    DefaultItemPipe,
    PagonamePipe,
    ConfigPipe,
    DatePipe
  ]
})
export class PipesModule {
}
