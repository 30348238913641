import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './shared/not-found/not-found.component';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./configuracion/configuracion.module').then(
        (m) => m.ConfiguracionModule
      ),
  },
  { path: '**', component: NotFoundComponent },
];

export const AppRoutes = RouterModule.forRoot(routes, {
  initialNavigation: 'enabledNonBlocking',
  scrollPositionRestoration: 'enabled',
  useHash: false,
  relativeLinkResolution: 'legacy',
});
