<div class="contenedor">
  <div id="grid" class="image-cropper">
    <img *ngFor="let imagen of imagenes; let i = index"
         (click)="verImg(imagen)"
         [alt]="imagen"
         [src]="imagen"
         [@move]="state"
         (@move.done)="onTerminar($event)"
         (@move.start)="onIniciar($event)"
         [attr.class]="i">
  </div>
  <div>
    <div id="slider-buttons-up" (click)="moverAbajo()">
      <mat-icon>keyboard_arrow_up</mat-icon>
    </div>
    <div id="slider-buttons-down" (click)="moverArriba()">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </div>
  </div>
</div>

