import {Pipe, PipeTransform} from '@angular/core';
import {FormaDeEntregaModel, FormaPagoModel, MetodoPagoModel, TipoVentaModel} from '../models/cuenta.model';
import {FormControl, UntypedFormGroup} from '@angular/forms';

@Pipe({
  name: 'defaultItem'
})
export class DefaultItemPipe implements PipeTransform {

  transform(items: any[], catalogo: string, formGroup: UntypedFormGroup, controlName: string, isAuth: boolean): any {
    switch (catalogo) {
      case 'tipoVenta':
        return this.tipoVentaDefault(formGroup, controlName, items);
      case 'metodoPago':
        return this.metodoPagoDefault(formGroup, controlName, items, isAuth);
      case 'formaEntrega':
        return this.formEntregaDefault(formGroup, controlName, items);
      case 'formaPago':
        return this.formaPagoDefault(formGroup, controlName, items);
    }
  }


  tipoVentaDefault(formGroup: UntypedFormGroup, controlName: string, items: TipoVentaModel[]) {
    formGroup.get(controlName).setValue(items[1]);
    return items[1];
  }

  metodoPagoDefault(formGroup: UntypedFormGroup, controlName: string, items: MetodoPagoModel[], isAuth: boolean) {
    const seleccionado = 'CONTRA ENTREGA';

    const item = items.find(metodo => metodo.Nombre === seleccionado);
    formGroup.get(controlName).setValue(item);
    return item;
  }

  formEntregaDefault(formGroup: UntypedFormGroup, controlName: string, items: FormaDeEntregaModel[]) {
    formGroup.get(controlName).setValue(items[0]);
    return items[0];
  }

  formaPagoDefault(formGroup: UntypedFormGroup, controlName: string, items: FormaPagoModel[]) {
    formGroup.get(controlName).setValue(items[0]);
    return items[0];
  }

}
