import {Pipe, PipeTransform} from '@angular/core';
import {Producto} from '../models/productos.model';

@Pipe({
  name: 'calcular'
})
export class CalcularPipe implements PipeTransform {

  transform(producto: Producto, args: string): any {
    switch (args) {
      case 'p1':
        return this.obtenerPrimerPrecio(producto);
      case 'p2':
        return this.obtenerSegundoPrecio(producto);
      case 'showp1':
        return this.mostrarP1(producto);
      default:
        return producto.Precio;
    }
  }

  toNumberFixed(monto: number): number {
    return Number(monto.toFixed(2));
  }

  obtenerPrimerPrecio(p: Producto): number {
    return this.toNumberFixed(p.Precio + (p.Precio * (p.Impuesto / 100))) || 0;
  }

  obtenerSegundoPrecio(p: Producto) {
    return this.toNumberFixed(p.PrecioFinal + (p.PrecioFinal * (p.Impuesto / 100))) || 0;
  }

  mostrarP1(p: Producto): boolean {
   return this.obtenerPrimerPrecio(p) > this.obtenerSegundoPrecio(p);
  }

}
