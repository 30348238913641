import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ContenidoTarjeta} from './Models/TarjetaHub';

@Component({
  selector: 'app-tarjeta-hub',
  templateUrl: './tarjeta-hub.component.html',
  styleUrls: ['./tarjeta-hub.component.scss']
})
export class TarjetaHubComponent implements OnInit {

  @Input('contenido') contenido: ContenidoTarjeta;
  @Output('boton') boton = new EventEmitter();
  @Output('editar') editar = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

}
