import {Injectable} from '@angular/core';
import {PlatformService} from './platform.service';
import {HttpErrorResponse} from '@angular/common/http';
import {showSwalError} from '../functions/sweet-alerts.functions';
import {parseError} from '../functions/utilities.functions';

@Injectable({
  providedIn: 'root'
})
export class UtilFuncService {

  constructor(private platform: PlatformService) {
  }

  showLoader(visible: boolean = true) {
    if (this.platform.isBrowser()) {
      if (visible) {
        document.getElementById('loader').classList.remove('hidden');
        return;
      }
      document.getElementById('loader').classList.add('hidden');
    }
  }

  reloadPage() {
    if (this.platform.isBrowser()) {
      window.location.reload();
    }
  }

  showError(error: HttpErrorResponse) {
    this.showLoader(false);
    const e = parseError(error);
    if (e.title !== '') {
      showSwalError(e.title, e.description);
    } else {
      showSwalError('Error de conexión', 'Ocurrio un error en la comunicación con el servidor, por favor intente más tarde');
    }
  }

}
