import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from './auth.service';
import {PlatformService} from '../platform.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private auth: AuthService,
              private platform: PlatformService,
              private router: Router) {}

  canActivate(): boolean {
    if (this.auth.isAuth()) {
      return true;
    }

    if (this.platform.isBrowser()) {
      this.router.navigate(['/']);
    }
    return false;
  }
}
