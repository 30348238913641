<div class="row">
  <div class="col-md-12">
    <mat-card-title>Existencias</mat-card-title>
  </div>
  <div class="col-md-12">
    <mat-card-subtitle>{{data.nombreProducto}}</mat-card-subtitle>
  </div>
</div>
<hr>
<div class="row contenedor">
  <div class="col-md-12">
    <table>
      <tr>
        <th></th>
        <th class="centrado">Nombre</th>
        <th class="centrado">Existencias</th>
      </tr>
      <tr *ngFor="let centro of centros">
        <td class="centrado"><mat-icon>store</mat-icon></td>
        <td>{{centro | uppercase}}</td>
        <td class="derecha">{{data.existencias[centro] | number: '.2-2'}}</td>
      </tr>
      <tr>
        <td class="centrado">TOTAL</td>
        <td></td>
        <td class="derecha total">{{totalExistencias | number: '.2-2'}}</td>
      </tr>
    </table>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-md-12" align="center">
    <button mat-button mat-raised-button [color]="'primary'" [mat-dialog-close]>Ok</button>
  </div>
</div>
