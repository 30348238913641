import { Pipe, PipeTransform } from '@angular/core';
import {ProductoKoreVisorus} from '../models/productos.model';

@Pipe({
  name: 'precio'
})
export class PrecioPipe implements PipeTransform {

  transform(producto: ProductoKoreVisorus, key: any): number {
    switch (key) {
      case 'precioFinal':
        return this.getPrecioConDescuento(producto);
      case 'impdesc':
        return this.getPrecioUnitarioConImpuestoYDescuento(producto);
      case 'almimp':
        return this.getPrecioAlmacenConImpuesto(producto);
      default:
        return producto.precio;
    }
  }

  getPrecioConDescuento(producto: ProductoKoreVisorus): number {
    const precioDescuentoPromo = producto.precio * (1 - (producto.descuento_promo / 100));
    return Number(precioDescuentoPromo * (1 - (producto.descuento / 100)));
  }

  getPrecioUnitarioConImpuestoYDescuento(producto: ProductoKoreVisorus): number {
    const precioDescuentoPromo = producto.precio * (1 - (producto.descuento_promo / 100));
    const precioDescCliente = precioDescuentoPromo * (1 - (producto.descuento / 100));
    const precioFinal = precioDescCliente + (precioDescCliente * (producto.impuesto / 100));
    return Number(precioFinal);
  }
  getPrecioAlmacenConImpuesto(producto: ProductoKoreVisorus) {
    const precio = producto.precio + (producto.precio * (producto.impuesto / 100));
    return Number(precio);
  }

  /*getPrecioConDescuento(producto: ProductoKoreVisorus): number {
    const precioDescuentoPromo = producto.precioalmacen * (1 - (producto.descuento_promo / 100));
    return Number((precioDescuentoPromo * (1 - (producto.descuento / 100))).toFixed(2));
  }

  getPrecioUnitarioConImpuestoYDescuento(producto: ProductoKoreVisorus): number {
    const precioDescuentoPromo = producto.precioalmacen * (1 - (producto.descuento_promo / 100));
    const precioDescCliente = Number((precioDescuentoPromo * (1 - (producto.descuento / 100))).toFixed(2));
    const precioFinal = (precioDescCliente + (precioDescCliente * (producto.impuestos / 100))).toFixed(2);
    return Number(precioFinal);
  }

  getPrecioAlmacenConImpuesto(producto: ProductoKoreVisorus) {
    const precio = (producto.precioalmacen + (producto.precioalmacen * (producto.impuestos / 100))).toFixed(2);
    return Number(precio);
  }
*/
}
