import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {throwError as observableThrowError, Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {catchError, map} from 'rxjs/operators';
import {PlatformService} from '../platform.service';
import {UtilFuncService} from '../util-func.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {
    private key = 'EcommerceToken';

    constructor(private injector: Injector,
                private util: UtilFuncService,
                private platform: PlatformService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const auth = this.injector.get(AuthService);

        return next.handle(req).pipe(
            catchError((response: any) => {
                if (response instanceof HttpErrorResponse) {
                    switch (response.status) {
                        case 401:
                            if (this.platform.isBrowser()) {
                              localStorage.removeItem(this.key);
                              this.util.reloadPage();
                            }
                            break;
                        case 403:
                            auth.logout();
                            break;
                    }
                }

                return observableThrowError(response);
            })
        );
    }
}
