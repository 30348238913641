import { Component, OnInit } from '@angular/core';
import {DisplayService} from '../../services/display.service';
import {ConfiguracionService} from '../../services/configuracion.service';
import {PlatformService} from '../../services/platform.service';
import {VersionService} from '../../services/version.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  version: string;
  anio = new Date().getFullYear();

  constructor(public display: DisplayService,
              public platform: PlatformService,
              public config: ConfiguracionService,
              public vercionService: VersionService) {
    this.version = vercionService.version;
  }

  ngOnInit() {
  }

}
