import Swal from 'sweetalert2';

export function showSwalError(titulo: string, descripcion: string) {
  return Swal.fire({
    type: 'error',
    title: titulo,
    text: descripcion
  });
}

export function showSwalWarning(titulo: string, descripcion: string) {
  return Swal.fire({
    type: 'warning',
    title: titulo,
    text: descripcion
  });
}

export function showSwalSuccess(titulo: string, descripcion: string) {
  return Swal.fire({
    type: 'success',
    title: titulo,
    text: descripcion
  });
}

export function showSwalInfo(titulo: string, descripcion: string) {
  return Swal.fire({
    type: 'info',
    title: titulo,
    text: descripcion
  });
}
