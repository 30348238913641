import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {TokenInterceptorService} from './token-interceptor.service';
import {AuthService} from './auth.service';
import {AuthGuardService} from './auth-guard.service';
import {ErrorInterceptorService} from './error-interceptor.service';
import {BlobErrorInterceptorService} from './blob-error-interceptor.service';

@NgModule({
  imports: [
    HttpClientModule,
    RouterModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorInterceptorService,
      multi: true
    },
    AuthService,
    AuthGuardService
  ]
})
export class AuthModule { }
