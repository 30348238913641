import { Pipe, PipeTransform } from '@angular/core';
import {environment} from '../../environments/environment';

@Pipe({
  name: 'hrefs'
})
export class HrefsPipe implements PipeTransform {

  transform(values: any[]): string {
    let newurl = environment.dominio;
    values.forEach(item => newurl += '/' + item );
    return newurl;
  }

}
