import {Pipe, PipeTransform} from '@angular/core';
import {environment} from '../../environments/environment';
import {_LIST_DEPARTAMENTOS_} from '../constantes/departamentos.const';

@Pipe({
  name: 'transformtxt'
})
export class TransformtxtPipe implements PipeTransform {

  transform(value: string, key: string): string {
    if (!value) {
      return '';
    }

    switch (key) {
      case 'depto':
        return 'en departamentos';
      case 'marca':
        return 'en marcas';
      case 'filename':
        return this.getFileName(value);
      case 'ruta':
        return this.getFileRute(value);
      case 'metodo_pago':
        return this.getTitleMetdoPago(value);
      case 'hiddencard':
        return this.getHiddenCardNum(value);
      case 'itemdepto':
        return this.getItemDepto(value);
      case 'keybusqueda':
        return this.getKeyDepartamentoSinClaves(value);
      case 'callcenter':
        return this.telefonoFormat(value);
      case 'urlamigable':
        return this.toURLAmigable(value);
      case '-':
        return this.cambiarEspacioPorGuion(value);
      default:
        return '';
    }
  }

  getFileName(fileRute: string): string {
    if (fileRute.length < 10) {
      return 'No disponible';
    }
    const ruta = fileRute.split('/');
    return ruta[ruta.length - 1];
  }

  getFileRute(fileRute: string): string {
    if (fileRute.length < 10) {
      return '#';
    }

    return `${environment.urlProductos}/${fileRute}`;
  }

  getTitleMetdoPago(txt: string): string {
    switch (txt) {
      case 'CONTRA ENTREGA':
        return 'Pago Contra Entrega';
      case 'TARJETA DEBITO':
        return 'Tarjeta de Débito';
      case 'TARJETA CREDITO':
        return 'Tarjeta de Crédito';
      case 'CREDITO':
        return 'Linea de Crédito';
    }
  }

  getHiddenCardNum(cardnum: string): string {
    return `XXXX-XXXX-XXXX-${cardnum.substring(12, 16)}`;
  }

  getItemDepto(name: string): string {
    const itemname = name.toUpperCase().replace('Ñ', 'N').replace(/\s/g, '').trim();
    return _LIST_DEPARTAMENTOS_[itemname];
  }

  getKeyDepartamentoSinClaves(key: string): string {
    const str = key.split('-');
    return str[str.length - 1];
  }

  telefonoFormat(telefono: string): string {
    if (!telefono) {
      return '';
    }
    telefono = telefono.replace(/\s/g, '').trim();
    return `${telefono.substring(0, 3)}-${telefono.substring(3, 6)}-${telefono.substring(6, 10)}`;
  }

  cambiarEspacioPorGuion(txt: string): string {
    return txt.trim().replace(/\s/g, '-');
  }

  toURLAmigable(nombre: string): string {
    if (!nombre) {
      return '';
    }

    // Simbolos que se remplazan por guion medio o algun caracter especial
    nombre = nombre.replace(/\s/g, '-').trim().toLocaleLowerCase();
    nombre = nombre.replace(/á/g, 'a');
    nombre = nombre.replace(/é/g, 'e');
    nombre = nombre.replace(/í/g, 'i');
    nombre = nombre.replace(/ó/g, 'o');
    nombre = nombre.replace(/ú/g, 'u');
    nombre = nombre.replace( /\./g, '-');
    nombre = nombre.replace( /\//g, '-');
    nombre = nombre.replace(/_/g, '-');

    // dejar estos simbolos y abreviaciones
   /* nombre = nombre.replace(/"/g, '-pulgadas-');
    nombre = nombre.replace(/pza/g, '-pieza-');
    nombre = nombre.replace(/pzs/g, '-piezas-');
    nombre = nombre.replace(/mm/g, '-milimetros-');
    nombre = nombre.replace(/kg/g, '-kilogramo-');
    nombre = nombre.replace(/btos/g, '-bultos-');
    nombre = nombre.replace(/mts/g, '-metros-');
    nombre = nombre.replace(/plg/g, '-pulgada-');
    nombre = nombre.replace(/lts/g, '-litros-');
    nombre = nombre.replace(/lbs/g, '-libras-');
    nombre = nombre.replace(/#/g, '-numero-');*/

    // Fracciones
   /* nombre = nombre.replace(/1\/2/g, 'un-medio');
    nombre = nombre.replace(/1\/4/g, 'un-cuarto');
    nombre = nombre.replace(/1\/8/g, 'un-octavo');
    nombre = nombre.replace(/3\/8/g, 'tres-octavos');
    nombre = nombre.replace(/3\/4/g, 'tres-cuartos');
    nombre = nombre.replace(/7\/8/g, 'siete-octavos');
    nombre = nombre.replace(/11\/2/g, 'once-medios');
    nombre = nombre.replace(/11\/4/g, 'once-cuartos');*/

    // Simbolos que se reemplazan por ''
    const compuestos = [/pza/g, /pzs/g, /mm/g, /kg/g, /btos/g, /plg/g, /mts/g, /lts/g, /lbs/g];
    const simbologia = [/p\//g, /c\//g, /\//g, /\./g, /\\/g, /\(/g, /\)/g]; // Simbolos con escape
    const numeros = [/0/g, /1/g, /2/g, /3/g, /4/g, /5/g, /6/g, /7/g, /8/g, /9/g];
    const caracteres = [/"/g, /%/g, /,/g, /#/g, /º/g, /ª/g, /°/g, /&/g, /:/g, /;/g, /=/g, /~/g ];

    compuestos.forEach(comp => nombre = nombre.replace(comp, ''));
    simbologia.forEach(key => nombre = nombre.replace(key, ''));
    // numeros.forEach(value => nombre = nombre.replace(value, ''));
    caracteres.forEach(value => nombre = nombre.replace(value, ''));

    nombre = nombre + '-';

    // Quitando palabras genericas
    nombre = nombre.replace(/-m-/g, '-');
    nombre = nombre.replace(/-y-/g, '-');
    nombre = nombre.replace(/-con-/g, '-');
    nombre = nombre.replace(/-para-/g, '-');
    nombre = nombre.replace(/-x-/g, '-');
    nombre = nombre.replace(/-cm-/g, '-');
    nombre = nombre.replace(/-num-/g, '-');
    nombre = nombre.replace(/-cal-/g, '-');
    nombre = nombre.replace(/-l-/g, '-');
    nombre = nombre.replace(/-w-/g, '-');
    nombre = nombre.replace(/-no-/g, '-');
    nombre = nombre.replace(/-mx-/g, '-');
    nombre = nombre.replace(/-sc-/g, '-');
    nombre = nombre.replace(/-de-/g, '-');
    nombre = nombre.replace(/-mgo-/g, '-');
    nombre = nombre.replace(/-cut-/g, '-');

    // Limpiando cadena de texto
    nombre = nombre.replace(/------/g, '-');
    nombre = nombre.replace(/-----/g, '-');
    nombre = nombre.replace(/----/g, '-');
    nombre = nombre.replace(/---/g, '-');
    nombre = nombre.replace(/--/g, '-');

    // Optimizando
    if (nombre.charAt(nombre.length - 1) === '-') {
      nombre = nombre.slice(0, nombre.length - 1);
    }

    // console.log(nombre);
    return nombre;
  }

  iversaURLAmigable(nombre: string): string {
    nombre = nombre.replace(/-por-/g, '-');
    nombre = nombre.replace(/-para-/g, '-');
    nombre = nombre.replace(/-con-/g, '-');
    nombre = nombre.replace(/-centimetros-/g, '-');
    nombre = nombre.replace(/-numero-/g, '-');
    nombre = nombre.replace(/-calibre-/g, '-');

    nombre = nombre.replace(/-/g, ' ');

    // dejar estos simbolos y abreviaciones
    nombre = nombre.replace(/pulgadas/g, '');
    nombre = nombre.replace(/pieza/g, '');
    nombre = nombre.replace(/piezas/g, '');
    nombre = nombre.replace(/milimetros/g, '');
    nombre = nombre.replace(/kilogramo/g, '');
    nombre = nombre.replace(/bultos/g, '');
    nombre = nombre.replace(/metros/g, '');
    nombre = nombre.replace(/pulgada/g, '');
    nombre = nombre.replace(/litros/g, '');
    nombre = nombre.replace(/libras/g, '');
    nombre = nombre.replace(/numero/g, '');

    // Fracciones
    nombre = nombre.replace(/un medio/g, ' ');
    nombre = nombre.replace(/un cuarto/g, ' ');
    nombre = nombre.replace(/un octavo/g, ' ');
    nombre = nombre.replace(/once medios/g, ' ');
    nombre = nombre.replace(/tres octavos/g, ' ');
    nombre = nombre.replace(/tres cuartos/g, ' ');

    nombre = nombre.replace(/     /g, ' ');
    nombre = nombre.replace(/    /g, ' ');
    nombre = nombre.replace(/   /g, ' ');
    nombre = nombre.replace(/  /g, ' ');

    return nombre.trim();
  }
}
