import {Component, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';


@Component({
  selector: 'app-carrusel-horizontal',
  templateUrl: './carrusel-horizontal.component.html',
  styleUrls: ['./carrusel-horizontal.component.scss'],
  animations: [
    trigger('move', [
      state('in', style({transform: 'translateX(0)'})),
      transition('void => left', [
        style({transform: 'translateX(100%)'}),
        animate(200)
      ]),
      transition('left => void', [
        animate(200, style({transform: 'translateX(0)'}))
      ]),
      transition('void => right', [
        style({transform: 'translateX(-100%)'}),
        animate(200)
      ]),
      transition('right => void', [
        animate(200, style({transform: 'translateX(0)'}))
      ])
    ])
  ]
})
export class CarruselHorizontalComponent implements OnInit {
  @Input() imagenes: string[] = [];
  sliderButtonsDisable = false;
  state = 'void';

  constructor() { }

  ngOnInit() {
  }

  rotarImagen(arreglo, reverse) {
    if (reverse) {
      arreglo.unshift(arreglo.pop());
    } else {
      arreglo.push(arreglo.shift());
    }
    return arreglo;
  }

  moverToIzquierda() {
    if (this.sliderButtonsDisable) {
      return;
    }
    this.state = 'right';
    this.rotarImagen(this.imagenes, true);
  }

  moverToDerecha() {
    if (this.sliderButtonsDisable) {
      return;
    }
    this.state = 'left';
    this.rotarImagen(this.imagenes, false);
  }

}
