/**
 * === IMPORTANTE AL MOMENTO DE CONSTRUIR ===
 *
 * Usar siempre estos valores en el entorno de desarrollo y pruebas, nunca usar en produccion
 * (produccion tiene datos de openpay de produccion) para el nodo de openpay:
 * mercanId: 'mxmhhzthx1ctjnsytpg0',
 * key: 'pk_f0a7f856b8524e40b7b518a525ff88f2',
 * sandboxMode: true
 *
 * El nodo @dominio, depende de donde este alojado el servicio.
 */
export const environment = {
  production: false,
  env: 'development',
  dominio:  'https://dev.ng-ecommerce.koreingenieria.com',
  urlEcommerce: 'https://dev.go-ecommerce.koreingenieria.com',
  urlFormasPago: 'https://dev.formaspagos.koreingenieria.com/api',
  urlVentas: 'https://dev.ventas.koreingenieria.com',
  urlInventarios: 'https://dev.inventarios.koreingenieria.com',
  urlProductos: 'https://dev.productos.koreingenieria.com',
  urlLogin: 'https://dev.login.koreingenieria.com',
  demo: true,
  openPay: {
    mercanId: 'mxmhhzthx1ctjnsytpg0',
    key:  'pk_f0a7f856b8524e40b7b518a525ff88f2',
    sandboxMode: true
  }
};
