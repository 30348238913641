import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class BlobErrorInterceptorService implements HttpInterceptor {
  /**
   * Intercepta la respuesta, si el error llega como blob lo combierte a
   * una respuesta que la funcion catchError() pueda manejar
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // @ts-ignore
    return next.handle(req).pipe(catchError(err => {
      if (err instanceof HttpErrorResponse && err.error instanceof Blob && err.error.type === 'application/json') {
        return new Promise<any>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e: Event) => {
            try {
              const errmsg = JSON.parse((<any>e.target).result);
              reject(new HttpErrorResponse({
                error: errmsg,
                headers: err.headers,
                status: err.status,
                statusText: err.statusText,
                url: err.url
              }));
            } catch (e) {
              reject(err);
            }
          };
          reader.onerror = (e) => {
            reject(err);
          };
          reader.readAsText(err.error);
        });
      }
      return throwError(err);
    }));
  }

}
