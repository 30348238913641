import { Pipe, PipeTransform } from '@angular/core';
import {_DEFAULT_IMAGE_} from '../constantes/globales.const';
import {environment} from '../../environments/environment';

@Pipe({
  name: 'noimagen'
})
export class NoimagenPipe implements PipeTransform {
  transform(producto: any): string[] {
    return this.obtenerArregloImg(producto);
  }

  obtenerArregloImg(producto: any) {
    if (producto.Imagenes && producto.Imagenes.length > 0) {
      const srcImg = [];
      producto.Imagenes.forEach(item => {
        const destruct = item.split('/');
        const imgName = destruct[destruct.length - 1];
        srcImg.push(`${environment.urlProductos}/productos/images/obtener/${imgName}`);
      });
      return srcImg;
    }

    return [_DEFAULT_IMAGE_];
  }

}
