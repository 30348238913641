import {Injectable} from '@angular/core';
import {ConfigData, ConfiguracionModel} from '../models/configuracion.model';
import {_CONFIG_KEY_ECOMMERCE_} from '../constantes/globales.const';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {RespuestaModel} from '../models/respuestas.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {PlatformService} from './platform.service';
import {UtilFuncService} from './util-func.service';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionService {
  private _dataConfig: BehaviorSubject<ConfigData>;
  DataConfig: Observable<ConfigData>;

  config: ConfiguracionModel;

  constructor(private http: HttpClient, private platform: PlatformService, private util: UtilFuncService) {
    this._dataConfig = <BehaviorSubject<ConfigData>> new BehaviorSubject<ConfigData>(undefined);
    this.DataConfig = this._dataConfig.asObservable();


    this.obtenerConfigData();
    this.initConfig();
  }

  initConfig() {
    if (this.platform.isBrowser()) {
      this.config = JSON.parse(localStorage.getItem(_CONFIG_KEY_ECOMMERCE_));

      if (this.isInvalid()) {
        this.config = new ConfiguracionModel();
        this.saveConfig();
      }
    }

    if (this.platform.isServer()) {
      this.config = new ConfiguracionModel();
      this.saveConfig();
    }
  }

  saveConfig() {
    if (this.platform.isBrowser()) {
      localStorage.setItem(_CONFIG_KEY_ECOMMERCE_, JSON.stringify(this.config));
    }
  }

  setConfig(key: string, value: any) {
    this.config[key] = value;
    this.saveConfig();
  }

  getConfig(): ConfiguracionModel {
    return this.config;
  }

  isTarjetaView(): boolean {
    return this.config.viewTarjeta;
  }

  isCarritoTable(): boolean {
    return this.config.carritoTable;
  }

  isInvalid(): boolean {
    return !this.config ||
      !this.config.sucursalId ||
      !this.config.sucursalAbv ||
      !this.config.sucursalNom ||
      !this.config.filtros ||
      this.config.carritoTable === undefined ||
      !this.config.sucursalId ||
      !this.config.temporalId;
  }

  obtenerConfigData() {
    this.http.get(`${environment.urlEcommerce}/v1/config-store`)
      .pipe(map((respuesta: RespuestaModel) => {
        const config = respuesta.Data as ConfigData;
        config.callCenter = config.callCenter.trim();
        return config;
      })).subscribe(data => this._dataConfig.next(data), this.util.showError);
  }
}
