import { Component, Inject, OnInit, Renderer2 } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

export const v_COOKIE_NAME = "cookieconsent";
export const v_ALLOW = "allow";
export const v_ONE_YEAR = 365;

@Component({
  selector: "app-banner-analitycs",
  templateUrl: "./banner-analitycs.component.html",
  styleUrls: ["./banner-analitycs.component.scss"],
})
export class BannerAnalitycsComponent implements OnInit {
  showBanner: boolean = true;

  constructor(
    @Inject("document") private document,
    @Inject("window") private window,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    const consent = this.getCookie(v_COOKIE_NAME);
    const isConsent = consent === v_ALLOW;
    this.showBanner = !isConsent;
    this.addScriptToDom({
      isConsent,
      isUpdated: false,
    });
  }

  private addScriptToDom({
    isConsent,
    isUpdated,
  }: {
    isConsent: boolean;
    isUpdated: boolean;
  }) {
    const script = this.renderer.createElement("script");
    script.type = "text/javascript";
    script.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}

      gtag('consent', '${isUpdated ? "update" : "default"}', {
        'ad_storage': '${isConsent ? "granted" : "denied"}',
        'ad_user_data': '${isConsent ? "granted" : "denied"}',
        'ad_personalization': '${isConsent ? "granted" : "denied"}',
        'analytics_storage': '${isConsent ? "granted" : "denied"}'
      });
    `;
    this.renderer.appendChild(document.body, script);
  }

  public btnAccept(): void {
    this.setCookie(v_COOKIE_NAME, v_ALLOW, v_ONE_YEAR);
    this.showBanner = false;
    this.addScriptToDom({
      isConsent: true,
      isUpdated: true,
    });
    this.window.location.reload();
  }

  public closeBanner(): void {
    this.setCookie(v_COOKIE_NAME, v_ALLOW, v_ONE_YEAR);
    this.showBanner = false;
  }

  private getCookie(cname) {
    const name = cname + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  private setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    this.document.cookie = cname + "=" + cvalue + ";" + expires;
  }
}
